import { ordersearch } from '../../services';
const state = () => ({
  status:false,
  error:null,
  orderTypes:null,
  cities:null,
  riders:null,
  sorts:null,
  searchLabel:{'orderType':null,'city':null,'rider':null,'sort':null},
  searchField:{'orderType':null,'city':null,'rider':null,'sort':null},
  search:{'orderType':0,'city':0,'rider':0,'sort':'','for_prepation':"1",'open_search':'','from_date':null,'till_date':null},
  orders:[],
  collectedorders:[],
  orderCheckbox:[],
})
// actions
const actions = {
  getSearchAttr({ commit }) {
        ordersearch.searchAttr()
            .then( response => {
                    if(response.status == 'success'){
                        commit('setSearchValues',response);
                    }else{
                      commit('setStatus', false);
                    }
                },
                error => {
                  commit('setStatus', false);
                  commit('getFailure', error);
                }
            );
    }, 
    updateSearchData({ dispatch,commit },{data,field}) {
      commit('updateSearches',{data,field});
      dispatch('searchOrders')
    },
    searchData({ dispatch, commit },data) {
      commit('setSearchData',data);
      dispatch('searchOrders')
    },
    doEmpty({ commit,dispatch },data) {
      commit('setEmptySearch',data);
      dispatch('searchOrders');
    },
    resetValue({ commit,dispatch },data) {
      commit('ResetSearchValue',data);
      dispatch('searchOrders');
    },
    resetAllValue({ commit,dispatch }) {
      commit('resetAllValue');
      dispatch('searchOrders');
    },
    orderCheckbox({commit},{value}){
      commit('setOrderCheckbox',value);
    },
    clearOrderCheckbox({commit}){
      let value = [];
      commit('setOrderCheckbox',value);
    },
    searchOrders({ state,commit }) {
      let data = state.search;
      if(data.from_date != null && data.till_date != null){
        ordersearch.searchOrders(data)
            .then( response => {
                    if(response.status == 'success'){
                        commit('setOrders',response);
                    }else{
                      commit('getFailure', false);
                    }
                },
                error => {
                  commit('getFailure', error);
                }
            );
      }
    },
}

// mutations
const mutations = {
  setSearchValues(state, response) {
    state.orderTypes = response.orderTypes;
    state.cities = response.cities;
    state.riders = response.riders;
    state.sorts = response.sorts;
    state.searchField.sort = response.sorts[0];
    state.searchField.city = response.cities[0];
    state.searchField.rider = response.riders[0];
    state.searchField.orderType = response.orderTypes[0];
    state.status = true;
  },
  resetAllValue(state) {
    state.searchField.sort = state.sorts[0];
    state.search.sort = 0;
    state.searchLabel.sort = null;
    state.searchField.city = state.cities[0];
    state.search.city = 0;
    state.searchLabel.city = null;
    state.searchField.rider = state.riders[0];
    state.search.rider = 0;
    state.searchLabel.rider = null;
    state.searchField.orderType = state.orderTypes[0];
    state.search.orderType = 0;
    state.searchLabel.orderType = null;
    state.search.from_date = null;
    state.search.till_date = null;
    state.search.open_search = '';
    state.search.for_prepation = '1';
    state.status = true;
  },
  setStatus(state, status) {
    state.status = status;
  },
  getFailure(state, error) {
    state.error = error;
  },
  setSearchData(state, data) {
    state.search = data;
  },
  setOrders(state, data) {
    state.orders = data.orders;
    state.collectedorders = data.collected_orders;
  },
  setEmptySearch(state, data) {
    state.search[data.variable_name] = data.value;
  },
  ResetSearchValue(state, data) {
    state.search[data.variable_name] = 0;
    state.searchLabel[data.variable_name] = null;
  },
  updateSearches(state, {data,field}) {
    let valuess = null;
    if(field == 'sort'){
      valuess = state.sorts;
    } else if(field == 'city'){
      valuess = state.cities;
    } else if(field == 'rider'){
      valuess = state.riders;
    } else if(field == 'orderType'){
      valuess = state.orderTypes;
    }
    state.search[field] = data[field].value;
    state.searchLabel[field] = data[field];
    state.searchField[field] = valuess[0];
  },
  setOrderCheckbox(state,value){
    state.orderCheckbox = value;
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}