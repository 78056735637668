import { createRouter, createWebHistory } from 'vue-router'

const routes = [
	{
        path: '/', component: () => import('../views/Layout.vue'), meta: { transitionName: 'slide' },
        children: [
            {   path: 'orders-list',  	component: () => import('../views/listing/OrdersList.vue'),   name: 'orderlisting' },
            {   path: 'order',  		component: () => import('../views/orderpage/Order.vue'),   name: 'order' }
        ]
	},
  { path: '/login', name: 'login', component: () => import('../views/Login.vue') }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user_token');

  if (authRequired && !loggedIn) {
    return next('/login');
  }
  if(!authRequired && loggedIn){
    return next('/orders-list');
  }

  if(loggedIn && to.path == '/'){
    return next('/orders-list');
  }
  next();
})
