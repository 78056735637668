import { userService } from '../../services';
import router from '../../router';
const userTokenLocal = JSON.parse(localStorage.getItem('user_token'));
const state = () => ({
  userToken: userTokenLocal ? { status: true , userToken: userTokenLocal } : { status: false, userToken: null },
  status:false,
  userData:null,
  userImage:null,
})

// actions
const actions = {
   login({ commit,dispatch },{ email, password }) {
        commit('requestStatus', true);
        userService.login(email, password)
          .then(
              user => {
                  if(user.status == 'success'){
                      commit('loginSuccess', user.token);
                      dispatch('alert/success', user.error, { root: true });   
                      router.push('/orders-list'); 
                  }else{
                      commit('loginFailure', user.error);
                      dispatch('alert/error', user.error, { root: true });                        
                  }
              },
              error => {
                  commit('loginFailure', error);
                  dispatch('alert/error', error, { root: true });
              }
          );
    },
   getUserData({ state,commit }) {
    if(state.status == false){
        userService.getUser()
          .then(
              response => {
                  if(response.status == 'success'){
                      commit('UserDataSuccess', response);
                  }else{
                      commit('UserDataFailure');
                  }
              }
          );
    }
   },
}

// mutations
const mutations = {
  loginSuccess(state, user) {
    state.userToken.status = true;
    state.userToken.userToken = user;
    state.status = false;
  },
  loginFailure(state) {
    state.userToken.status = false;
    state.userToken.userToken = null;
    state.status = false;
  },
  UserDataSuccess(state,response) {
    state.userImage = response.userImage;
    state.userData = response.userData;
    state.status = true;
  },
  UserDataFailure(state) {
    state.userImage = null;
    state.userData = null;
    state.status = false;
  },
  requestStatus(state, status) {
    state.status = status;
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}