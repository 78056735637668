import constants from '../constants/globalConstants'
import {authHeader} from '../constants/auth-header'
import {handleResponseSeprate} from '../constants/auth-header'
export const userService = {
    login,getUser
};

function login(email, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })
    };
    return fetch(`${constants.apiUrl}api/pos/checkLogin`, requestOptions)
        .then(handleResponse)
        .then(user => {
            if (user && user.status == 'success' && user.token) {
                localStorage.setItem('user_token', JSON.stringify(user.token));
            }
            return user;
        });
}
function getUser() {
    const requestOptions = {
        method: 'get',
        headers: authHeader()
    };
    return fetch(`${constants.apiUrl}api/pos/getUserData`, requestOptions)
        .then(handleResponseSeprate)
        .then(response => {
            if (response && response.status == 'success') {
                return response;
            }
        });
}
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}