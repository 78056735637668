import constants from '../constants/globalConstants'
import {authHeader} from '../constants/auth-header'
import {handleResponse} from '../constants/auth-header'
export const orderprinting = {
    printOrders,
    printLabels,
};

function printOrders(data) {
    const requestOptions = {
        method: 'post',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${constants.apiUrl}api/pos/printOrders`, requestOptions)
        .then(handleResponse)
        .then(response => {
            if (response && response.status == 'success') {
                 return response;             
            }
        });
}
function printLabels(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${constants.apiUrl}api/pos/printLabels`, requestOptions)
        .then(handleResponse)
        .then(response => {
            if (response && response.status == 'success') {
                 return response;             
            }
        });
}