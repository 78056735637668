import constants from '../constants/globalConstants'
import {authHeader} from '../constants/auth-header'
import {handleResponse} from '../constants/auth-header'
export const cancelorder = {
    cancelOrder,
};

function cancelOrder(data) {
     const requestOptions = {
        method: 'post',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${constants.apiUrl}api/pos/cancelOrders`, requestOptions)
        .then(handleResponse)
        .then(response => {
            if (response && response.status == 'success') {
                 return response;             
            }
        });
}