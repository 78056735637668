export function authHeader() {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('user_token'));
    // console.log(user);
    if (user) {
        return { 'Access-Control-Allow-Origin':'*',"Content-type": "Application/json",'Access-Control-Allow-Headers':'Accept','Authorization': 'Basic ' + user,'X-API-Key': 'Bearer ' + user };
    } else {
        return {};
    }
}
export function checkLogin(data) {
    if (data.status === 'failed' && data.error === 'not_logged') {
		localStorage.removeItem('user_token');
		window.location.reload(true);
	}
}
export function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }else if(data.status === 'failed'){
            checkLogin(data);
        }
        return data;
    });
}
export function handleResponseSeprate(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }else if(data.status === 'failed'){
            checkLogin(data);
        }
        return data;
    });
}