import constants from '../constants/globalConstants'
import {authHeader} from '../constants/auth-header'
import {handleResponse} from '../constants/auth-header'
export const ordersearch = {
    searchAttr,
    searchOrders,
};

function searchAttr() {
    const requestOptions = {
        method: 'Get',
        headers: authHeader()
    };
    return fetch(`${constants.apiUrl}api/pos/getOrderSearchAttr`, requestOptions)
        .then(handleResponse)
        .then(user => {
            if (user && user.status == 'success') {
                // localStorage.setItem('user_token', JSON.stringify(user.token));
            }
            return user;
        });
}
function searchOrders(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${constants.apiUrl}api/pos/getOrderSearch`, requestOptions)
        .then(handleResponse)
        .then(response => {
            if (response && response.status == 'success') {
                 return response;             
            }
        });
}