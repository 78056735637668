import { createStore } from 'vuex'
import user from './modules/user.module'
import ordersearch from './modules/ordersearch.module'
import {alert} from './modules/alert.module'

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user,
    ordersearch,
    alert
  }
})
